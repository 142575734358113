import React, { useEffect, useState } from 'react';
import {
	CircularProgress,
	Typography,
	Box,
	makeStyles,
} from '@material-ui/core';

const STEP = 100; // ms

const useStyles = makeStyles((theme) => ({
	track: {
		border: '5px solid',
		width: 'calc(100% - 10px)',
		height: 'calc(100% - 10px)',
		borderRadius: '100%',
		position: 'absolute',
	},
	number: {
		fontWeight: 'bold',
	},
}));

const useCountdown = (duration, onUpdate, onComplete) => {
	useEffect(() => {
		let relevant = true;
		let timeLeft = duration;
		const startTime = Date.now();
		let lastUpdate = Infinity;
		requestAnimationFrame(function onFrame() {
			if (!relevant) {
				return;
			}

			timeLeft = Math.max(0, duration - (Date.now() - startTime));
			if (lastUpdate - timeLeft > STEP) {
				onUpdate(timeLeft);
				lastUpdate = timeLeft;
			}

			if (timeLeft <= 0) {
				onComplete();
			} else {
				requestAnimationFrame(onFrame);
			}
		});

		return () => (relevant = false);
	}, [duration, onUpdate, onComplete]);
};

/** @deprecated use @common/src/components/countdownIndicator */
export default function CountdownIndicator({ duration, sound, onComplete }) {
	const [timeLeft, setTimeLeft] = useState(duration);
	const classes = useStyles();

	useCountdown(duration, setTimeLeft, onComplete);

	return (
		<Box position="relative" display="inline-flex" style={{ width: 120 }}>
			<CircularProgress
				color="inherit"
				variant="static"
				value={(timeLeft / duration) * 100}
				size={120}
				thickness={6}
			/>
			<Box
				top={0}
				left={0}
				bottom={0}
				right={0}
				position="absolute"
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				<div className={classes.track} />
				<Typography
					className={classes.number}
					variant="h1"
					component="div"
					color="textPrimary"
				>
					{Math.floor(timeLeft / 1000)}
				</Typography>
			</Box>
		</Box>
	);
}
