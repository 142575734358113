import React from 'react';
import { BaseProviders } from '@remote-social/common/src';
import { appConfig } from '../appConfig';
import store from './index';
import { TriviaStoreProvider } from './triviaStore';
import { createTriviaTheme } from '../triviaTheme';

const triviaTheme = createTriviaTheme();

export const AppProvider: React.ComponentType = ({ children }) => (
	<BaseProviders store={store} appConfig={appConfig} theme={triviaTheme}>
		<TriviaStoreProvider>{children}</TriviaStoreProvider>
	</BaseProviders>
);
