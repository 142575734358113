// modules
import React from 'react';
import clsx from 'clsx';
// react-spring
import { animated as a, useSpring } from 'react-spring';
// material-ui
import { makeStyles, withWidth } from '@material-ui/core';
import { useConfigFlag } from '@common/hooks';
import { makeHSLA } from '@common';
import { triviaPurpleDark } from '../triviaTheme';

// given a mouse position, calculate the mouse offset relative to the centre of container/window
const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];

// adjust the mouse offset for parallax based on layer depty
// (x/2) = move image 1 pixel for every 2 pixel mouse offset (half speed)
// lower numbers mean more dramatic image movement
const trans1 = (x, y) =>
	`translate(${(x / 30).toFixed(2)}px, ${(y / 30).toFixed(2)}px)`;
const trans2 = (x, y) =>
	`translate(${(x / 15).toFixed(2)}px, ${(y / 15).toFixed(2)}px)`;
const trans3 = (x, y) =>
	`translate(${(x / 6).toFixed(2)}px, ${(y / 6).toFixed(2)}px)`;

// css styling
const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		zIndex: 0,
		overflow: 'hidden',
		background: makeHSLA(triviaPurpleDark),
	},
	bgImg: {
		position: 'absolute',
		left: '-5%',
		top: '-5%',
		width: '110%',
		height: '110%',
		backgroundSize: 'cover',
		backgroundPosition: 'left top',
		backgroundRepeat: 'no-repeat',
	},
	// individual layer images and blur
	layer1: {
		backgroundImage:
			'url("https://cdn.remotesocial.io/trivia/img/BG_layer-1.png")',
	},
	layer2: {
		backgroundImage:
			'url("https://cdn.remotesocial.io/trivia/img/BG_layer-2.png")',
	},
	layer3: {
		backgroundImage:
			'url("https://cdn.remotesocial.io/trivia/img/BG_layer-3.png")',
	},
}));

const lowMemoryDeviceOrSmallScreen = (width) => {
	const lowMemory =
		global.navigator &&
		typeof global.navigator.deviceMemory === 'number' &&
		global.navigator.deviceMemory <= 1;
	return lowMemory || width === 'xs';
};

// component
export const ParallaxBg = withWidth()(({ className, width }) => {
	const classes = useStyles(); // css
	// react-spring animation variable
	const [{ xy }, set] = useSpring(() => ({
		xy: calc(0, 0),
		config: { mass: 5, tension: 450, friction: 90 },
	}));

	// mousemove callback function
	const handleMouse = React.useCallback(
		({ clientX: x, clientY: y }) => {
			set({
				xy: calc(x, y), // recalculate xy based on new mouse coords
			});
		},
		[set],
	);

	const flagValue = useConfigFlag('disableParallaxBackground').asBoolean();
	const disableParallax = flagValue || lowMemoryDeviceOrSmallScreen(width);

	React.useEffect(() => {
		if (disableParallax) {
			return;
		}
		// bind mousemove event to the document for parallax
		document.addEventListener('mousemove', handleMouse);
		return () => {
			// unbind the event should this element be removed
			document.removeEventListener('mousemove', handleMouse);
		};
	}, [disableParallax, handleMouse]);

	return (
		<div className={clsx(className, classes.root)}>
			{disableParallax ? (
				<>
					<div className={clsx(classes.layer1, classes.bgImg)} />
					<div className={clsx(classes.layer2, classes.bgImg)} />
					<div className={clsx(classes.layer3, classes.bgImg)} />
				</>
			) : (
				<>
					<a.div
						className={clsx(classes.layer1, classes.bgImg)}
						style={{ transform: xy.interpolate(trans1) }}
					/>
					<a.div
						className={clsx(classes.layer2, classes.bgImg)}
						style={{ transform: xy.interpolate(trans2) }}
					/>
					<a.div
						className={clsx(classes.layer3, classes.bgImg)}
						style={{ transform: xy.interpolate(trans3) }}
					/>
				</>
			)}
		</div>
	);
});
