import React from 'react';
import { Link, makeStyles } from '@material-ui/core';
import {
	Link as RouterLink,
	Route,
	Switch,
	useLocation,
} from 'react-router-dom';
import { AppLayout, MainContent, PageNotFound } from '@common/components';
import {
	AuthenticatedHostRoute,
	AuthenticatedRoute,
	getAuthRoutes,
	routes,
} from '@common/routes';
import { ParallaxBg } from './components/parallaxBG.js';
import Home from './features/home/Home';
import Create from './features/create/Create';
import Game from './features/game/Game';
import TriviaLogo from './components/TriviaLogo';

import imgBackLayer3 from './assets/images/BG_layer-3.svg';
import imgBackLayer2 from './assets/images/BG_layer-2.svg';
import imgBackLayer1 from './assets/images/BG_layer-1.svg';
import { makeHSLA } from '@common';
import { triviaPurpleDark } from './triviaTheme';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		minHeight: 'calc(100vh + 200px)', // footer always below the fold
		backgroundColor: '#10085c',
		overflow: 'hidden',
	},
	triviaHeader: {
		textAlign: 'center',
		position: 'relative',
	},
	logoWrapper: {
		display: 'inline-block',
	},
	logo: {
		width: 200,
		height: 'auto',
		margin: theme.spacing(2, 'auto'),
		fill: theme.palette.primary.main,
		color: makeHSLA(triviaPurpleDark),
		zIndex: 2,
		[theme.breakpoints.up('sm')]: {
			width: 300,
		},
	},
	background: {
		position: 'absolute',
		top: 0,
		bottom: 200,
		left: 0,
		right: 0,
		backgroundColor: theme.palette.primary.main,
		backgroundPosition: `0px 0px,0px 0px,0px 0px`,
		backgroundImage:
			`url(${imgBackLayer3}), ` +
			`url(${imgBackLayer2}), ` +
			`url(${imgBackLayer1})`,
		backgroundSize: 'contain',
		[theme.breakpoints.up('md')]: {
			bottom: 120,
		},
		[theme.breakpoints.down('sm')]: {
			bottom: 90,
		},
	},
	main: {
		flex: 3,
		paddingBottom: '2rem',
	},
}));

export default function App() {
	const location = useLocation();
	const classes = useStyles();

	return (
		<AppLayout className={classes.root}>
			<ParallaxBg />
			<div className={classes.triviaHeader}>
				<Link
					className={classes.logoWrapper}
					component={RouterLink}
					to={routes.home({ location })}
				>
					<TriviaLogo className={classes.logo} />
				</Link>
			</div>
			<MainContent className={classes.main}>
				<Switch>
					{/* Home Route */}
					<Route exact path="/" component={Home} />

					{/* Auth Routes */}
					{getAuthRoutes()}

					{/* Game Routes */}
					<AuthenticatedHostRoute
						exact
						path="/create/:account/:game"
						component={Create}
					/>
					<AuthenticatedRoute
						path="/game/:account/:game"
						component={Game}
						redirectToPath="/register"
					/>
					{/* Default Route (404) */}
					<Route component={PageNotFound} />
				</Switch>
			</MainContent>
		</AppLayout>
	);
}
