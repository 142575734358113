import React from 'react';
import { Container, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
	},
	paper: {
		padding: '2rem',
		borderRadius: '2.5rem',
		boxShadow: theme.shadows[5],
		borderColor: theme.palette.common.white,
		borderWidth: 4,
		borderStyle: 'solid',
		width: '100%',
		marginTop: '2.5rem',
		position: 'relative',
		zIndex: 1,
	},
	animatedPaperBackground: {
		borderRadius: '2.5rem',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: -1,
	},
	contentWrapper: {
		width: '100%',
		position: 'relative',
		zIndex: 1,
		padding: theme.spacing(0, 4),
	},
	footer: {
		marginTop: 'auto',
		padding: theme.spacing(4),
		display: 'flex',
		width: '100%',
		alignItems: 'flex-end',
		justifyContent: 'center',
		zIndex: 1,
		[theme.breakpoints.up('sm')]: {
			justifyContent: 'flex-start',
		},
	},
	footerLogo: {
		width: 50,
		height: 'auto',
		[theme.breakpoints.up('sm')]: {
			width: 200,
		},
	},
}));

export default function Layout({
	className,
	children,
	maxWidth,
	heading,
	...rest
}) {
	const classes = useStyles();
	return (
		<Container
			maxWidth={maxWidth}
			fixed
			className={classes.container}
			{...rest}
		>
			<Paper className={clsx(className, classes.paper)} elevation={0}>
				{children}
			</Paper>
		</Container>
	);
}
