import React from 'react';
import { ensureExists } from '@common';

const Context = React.createContext(null);

export const TriviaStoreProvider: React.ComponentType = ({ children }) => {
	return <Context.Provider value={null}>{children}</Context.Provider>;
};

export function useTriviaStore() {
	const value = React.useContext(Context);
	return ensureExists(value);
}
