import React from 'react';
import { useSelector } from 'react-redux';
import {
	Avatar,
	fade,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
	Typography,
} from '@material-ui/core';
import { getPlayerScores } from '../store/selectors';
import { makeHSLA } from '@common';
import { triviaPurple } from '../triviaTheme';

const useStyles = makeStyles((theme) => ({
	list: {
		margin: theme.spacing(0, 2),
	},
	item: {
		borderRadius: '0.25rem',
		backgroundColor: fade(theme.palette.common.white, 0.5),
		marginBottom: theme.spacing(2),
	},
	text: {
		fontWeight: 'bold',
		color: makeHSLA(triviaPurple),
	},
}));

export default function Leaderboard({ gameId }) {
	const classes = useStyles();
	const playerScores = useSelector(getPlayerScores(gameId));

	return (
		<div className={classes.root}>
			<Typography variant="h2" align="center" gutterBottom>
				Leaderboard
			</Typography>
			<List className={classes.list}>
				{playerScores.map((player) => (
					<ListItem key={player.id} className={classes.item}>
						<ListItemAvatar>
							<Avatar variant="square" src={player.photoURL} />
						</ListItemAvatar>
						<ListItemText>
							<Typography
								className={classes.text}
								color="primary"
							>
								{player.displayName}
							</Typography>
						</ListItemText>
						<ListItemSecondaryAction>
							<Typography
								className={classes.text}
								color="primary"
							>
								{player.score}
							</Typography>
						</ListItemSecondaryAction>
					</ListItem>
				))}
			</List>
		</div>
	);
}
