import React, {
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import {
	AudioControl,
	DocumentHead,
	GamePlayers,
	getConfig,
	getGameHost,
	getGameState,
	getPlayersData,
	isPending,
	Loading,
	ResizeObserver,
	useCurrentAccount,
	useCurrentUser,
	useGame,
	usePrevious,
} from '@remote-social/common';
import {
	useFirebase,
	useFirebaseConnect,
	useFirestoreConnect,
} from 'react-redux-firebase';
import {
	Box,
	Button,
	Checkbox,
	debounce,
	FormControlLabel,
	Grid,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { animated, useSpring, useTransition } from 'react-spring';
import Layout from '../layout/Layout';
import {
	getCurrentCategory,
	getCurrentCategoryId,
	getCurrentCategoryName,
	getQuestion,
	getQuestionCount,
	getQuestionDuration,
	getQuestionIndexToAnswer,
	getQuestionIntermission,
	getRoundId,
	getRoundNumber,
	getTotalQuestions,
	isGameOver,
} from '../../store/selectors';
import CountdownIndicator from '../../components/CountdownIndicator';
import Leaderboard from '../../components/Leaderboard';
import Options from '../../components/Options';
import { CategoryLogo } from '../../components/CategoryLogo';
import useSound from 'use-sound';
import { envHost } from '@remote-social/common/src/utils/envLink';
import { GameState } from '@contracts/platform';

import sndPopCulture from '../../assets/audio/popCulture.mp3';
import sndOceanDay from '../../assets/audio/oceanday.mp3';
import sndHistory from '../../assets/audio/history.mp3';
import sndSports from '../../assets/audio/sports.mp3';
import sndFilm from '../../assets/audio/film.mp3';
import sndGeography from '../../assets/audio/geography.mp3';
import sndMusic from '../../assets/audio/music.mp3';
import sndVideoGame from '../../assets/audio/videogame.mp3';
import sndEighties from '../../assets/audio/eighties.mp3';
import sndChristmas from '../../assets/audio/christmas.mp3';
import sndYear from '../../assets/audio/newYear.mp3';
import sndValentines from '../../assets/audio/valentines.mp3';
import sndStPatricks from '../../assets/audio/stpatricks.mp3';
import sndGeneral from '../../assets/audio/general.mp3';
import sndThinkingBeeps from '../../assets/audio/thinking_beeps.mp3';
import sndBollywood from '../../assets/audio/bollywood.mp3';
import { useBackendFunction } from '@remote-social/common/src/hooks/useBackendFunction';

const soundByCategory = new Map([
	['popCulture', sndPopCulture],
	['oceanDay', sndOceanDay],
	['historyScience', sndHistory],
	['sports', sndSports],
	['filmTelevision', sndFilm],
	['geography', sndGeography],
	['music', sndMusic],
	['videoGames', sndVideoGame],
	['eightiesNineties', sndEighties],
	['xmas', sndChristmas],
	['newYear', sndYear],
	['valentines', sndValentines],
	['stpatricks', sndStPatricks],
	['generalKnowledge', sndGeneral],
	['bollywood', sndBollywood],
]);

const useStyles = makeStyles((theme) => ({
	layout: {
		padding: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontWeight: 'bold',
		padding: theme.spacing(2),
		marginBottom: theme.spacing(4),
	},
	volumeControl: {
		position: 'absolute',
		border: 0,
		top: -18,
		right: 28,
		padding: 0,
		margin: 0,
		borderRadius: '50%',
		width: 36,
		minWidth: 36,
		height: 36,
		lineHeight: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		outline: 0,
		zIndex: 1,
	},
	animatingContainer: {
		overflow: 'hidden',
	},
	animatingContent: {
		width: '100%',
		left: 0,
		right: 0,
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		flex: 1,
		padding: theme.spacing(2),
	},
	progressIndicator: {
		margin: theme.spacing(4, 0, 0),
	},
	round: {
		textTransform: 'uppercase',
	},
	questionTextContainer: {
		position: 'relative',
	},
	questionText: {
		top: 0,
		fontWeight: 'bold',
	},
	categoryLogo: {
		marginTop: theme.spacing(2),
	},
	players: {
		padding: theme.spacing(2),
	},
	gameSettings: {
		padding: theme.spacing(2),
	},
	checkbox: {
		cursor: 'default',
	},
	button: {
		margin: theme.spacing(2, 0),
	},
}));

export default function Game() {
	const classes = useStyles();
	const { account, game } = useParams();
	const { currentAccountId, setCurrentAccount } = useCurrentAccount();
	const history = useHistory();
	const firebase = useFirebase();
	const user = useCurrentUser();
	const gameId = account && game ? `${account}-${game}` : null;
	const hasJoinedGame = useGame(gameId);
	const [questionIndex, setQuestionIndex] = useState(undefined);
	const questionToAnswerIndex = useSelector(getQuestionIndexToAnswer(gameId));
	const host = useSelector(getGameHost(gameId));
	const category = useSelector(getCurrentCategory(gameId));
	const categoryId = useSelector(getCurrentCategoryId(gameId));
	const categoryName = useSelector(getCurrentCategoryName(gameId));
	const roundId = useSelector(getRoundId(gameId));
	const previousRoundId = usePrevious(roundId);
	const roundNumber = useSelector(getRoundNumber(gameId));
	const totalQuestions = useSelector(getTotalQuestions(gameId));
	const questionCount = useSelector(getQuestionCount(gameId));
	const gameState = useSelector(getGameState(gameId));
	const gameOver = useSelector(isGameOver(gameId));
	const hasGameStarted = gameState === GameState.game;
	const isHost = host === user.uid;
	const { isMuted, toggleMute } = useContext(AudioControl);

	const gameLoaded = roundId != null || gameState === GameState.lobby;

	const [page, setPage] = useState('lobby');

	const hostPath = gameId && `/create/${account}/${game}`;

	const requestNextQuestion = useCallback(async () => {
		if (!isHost) {
			return;
		}

		const nextQuestion = firebase
			.functions()
			.httpsCallable('trivia-nextQuestion');
		await nextQuestion({ gameId });
	}, [firebase, gameId, isHost]);

	const handleStartRound = useCallback(async () => {
		setPage('question');
		setQuestionIndex(0);
	}, []);

	const handleNextQuestion = useCallback(async () => {
		if (gameOver || questionIndex === totalQuestions - 1) {
			setPage('roundSummary');
			return;
		}

		setQuestionIndex((state) => state + 1);
	}, [gameOver, questionIndex, totalQuestions]);

	const handleAnswer = useCallback(
		async (answer) => {
			const submitAnswer = firebase
				.functions()
				.httpsCallable('trivia-submitAnswer');
			await submitAnswer({
				gameId,
				answer,
			});
		},
		[firebase, gameId],
	);

	const handleExit = useCallback(() => {
		history.push('/');
	}, [history]);

	let subscriptions = [];

	useLayoutEffect(() => {
		if (!gameLoaded) {
			return;
		}
		setPage(
			gameOver ? 'roundSummary' : hasGameStarted ? 'preRound' : 'lobby',
		);
		// we only want the gameOver logic to run at page load.
		// otherwise it preempts showing the answer for the last question
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gameLoaded, hasGameStarted]);

	useEffect(() => {
		if (!gameLoaded) {
			return;
		}
		if (roundId !== previousRoundId && questionIndex !== -1 && !gameOver) {
			if (questionIndex === undefined) {
				// just loaded the page
				if (questionToAnswerIndex !== -1) {
					setQuestionIndex(questionToAnswerIndex);
					setPage('question');
					return;
				} else if (questionCount > 0) {
					// questions exist, but none unanswered: we are finishing the last round
					setQuestionIndex(questionCount - 1);
					setPage('roundSummary');
					if (isHost) {
						requestNextQuestion();
					}
					return;
				}
				// else fall through to starting/creating a new round
			}
			setQuestionIndex(-1);
			setPage('preRound');

			if (isHost) {
				requestNextQuestion();
			}
		}
	}, [
		gameLoaded,
		roundId,
		questionIndex,
		questionCount,
		questionToAnswerIndex,
		previousRoundId,
		requestNextQuestion,
		gameOver,
		isHost,
	]);

	if (gameId) {
		subscriptions = [
			...subscriptions,
			{
				path: `/games/${gameId}/host`,
			},
			{
				path: `/games/${gameId}/state`,
			},
		];

		if (hasJoinedGame) {
			subscriptions = [
				...subscriptions,
				{
					path: `/games/${gameId}/playersData`,
				},
				{
					path: `/games/${gameId}/config`,
				},
				{
					path: `/games/${gameId}/roundData`,
				},
			];
		}
	}

	useFirebaseConnect(subscriptions);

	useFirestoreConnect([{ collection: 'games/trivia/categories' }]);

	useEffect(() => {
		if (account && hasJoinedGame && account !== currentAccountId) {
			setCurrentAccount(account);
		}
	}, [account, hasJoinedGame, currentAccountId, setCurrentAccount]);

	if (isHost && gameState === GameState.lobby) {
		return <Redirect to={hostPath} />;
	}

	if (!gameLoaded) {
		return (
			<Layout className={classes.layout} maxWidth="sm">
				<DocumentHead title="Play" />
				<Button
					className={classes.volumeControl}
					variant="contained"
					size="small"
					aria-label="toggle audio"
					onClick={toggleMute}
				>
					{isMuted ? (
						<VolumeOffIcon fontSize="small" />
					) : (
						<VolumeUpIcon fontSize="small" />
					)}
				</Button>
				<Loading showRandomLabels={true} />
			</Layout>
		);
	}

	return (
		<Layout className={classes.layout} maxWidth="sm">
			<DocumentHead title="Play" />
			<Button
				className={classes.volumeControl}
				variant="contained"
				size="small"
				aria-label="toggle audio"
				onClick={toggleMute}
			>
				{isMuted ? (
					<VolumeOffIcon fontSize="small" />
				) : (
					<VolumeUpIcon fontSize="small" />
				)}
			</Button>
			{page === 'question' ? (
				<Typography component="div" className={classes.header}>
					<div>
						<Typography variant="caption" className={classes.round}>
							Round {roundNumber}
						</Typography>
						<div>{category || categoryName}</div>
					</div>
					<Typography variant="h3">
						{questionIndex + 1} of {totalQuestions}
					</Typography>
				</Typography>
			) : null}
			{page === 'preRound' ? (
				<Typography component="div" className={classes.header}>
					<span>Round {roundNumber}</span>
					<span>{totalQuestions} Questions</span>
				</Typography>
			) : null}
			<AnimatedContainer className={classes.animatingContainer}>
				<AnimatedContent
					className={classes.animatingContent}
					page={page}
					gameId={gameId}
					categoryId={categoryId}
					categoryName={categoryName}
					questionIndex={questionIndex}
					onStartRound={handleStartRound}
					onEndRound={
						gameOver
							? handleExit
							: isHost
							? requestNextQuestion
							: null
					}
					onQuestionTimesUp={requestNextQuestion}
					onQuestionAnswered={handleAnswer}
					onNextQuestion={handleNextQuestion}
				/>
			</AnimatedContainer>
		</Layout>
	);
}

const AnimatedContainer = ({ className, children }) => {
	const [contentRef, { height }] = useMeasure();
	const [animatedHeight, set] = useSpring(() => ({ height: height || 100 }));

	const reactToHeight = useCallback(
		(paper, paperHeight, height) => {
			set({ height: height || 100 });
			scrollIfOffscreen(paper, paperHeight);
		},
		[set],
	);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedReactToHeight = useCallback(debounce(reactToHeight, 200), [
		reactToHeight,
	]);
	useEffect(() => {
		const paper = contentRef.current.closest('.MuiPaper-root');
		// offsetParent is the paper, so offsetTop is relative to paper.
		const paperHeight = contentRef.current.offsetTop + height;
		debouncedReactToHeight(paper, paperHeight, height);
	}, [contentRef, height, debouncedReactToHeight]);

	return (
		<div style={{ height }}>
			<animated.div style={animatedHeight} className={className}>
				<div ref={contentRef}>{children}</div>
			</animated.div>
		</div>
	);
};

const AnimatedContent = ({
	className,
	page,
	gameId,
	categoryId,
	categoryName,
	questionIndex,
	onStartRound,
	onEndRound,
	onQuestionTimesUp,
	onQuestionAnswered,
	onNextQuestion,
}) => {
	const pageTransitions = useTransition(page, null, {
		from: { opacity: 0, transform: 'translateY(100%)' },
		enter: { opacity: 1, transform: 'translateY(0%)' },
		leave: {
			opacity: 0,
			transform: 'transformY(100%)',
			position: 'absolute',
		},
	});

	const pages = {
		lobby: () => <LobbyPage gameId={gameId} />,
		preRound: () => (
			<PreRoundPage
				gameId={gameId}
				categoryId={categoryId}
				categoryName={categoryName}
				onNext={onStartRound}
			/>
		),
		roundSummary: () => (
			<RoundSummaryPage
				gameId={gameId}
				categoryId={categoryId}
				categoryName={categoryName}
				onNext={onEndRound}
			/>
		),
		question: () => (
			<QuestionPage
				gameId={gameId}
				questionIndex={questionIndex}
				onTimesUp={onQuestionTimesUp}
				onAnswer={onQuestionAnswered}
				onNext={onNextQuestion}
			/>
		),
	};

	return (
		<>
			{pageTransitions.map(({ item, key, props }) => (
				<animated.div key={key} style={props} className={className}>
					{pages[item]({})}
				</animated.div>
			))}
		</>
	);
};

const CategoriesDisplay = ({ categories, label }) => {
	const classes = useStyles();

	return (
		categories &&
		Object.keys(categories).length > 0 && (
			<>
				<Typography variant="h6">{label}</Typography>
				<Grid container>
					{Object.keys(categories).map((id) => (
						<Grid item key={id} sm={6}>
							<FormControlLabel
								className={classes.checkbox}
								control={
									<Checkbox
										disableRipple
										value={id}
										checked={categories[id].selected}
										color="default"
										className={classes.checkbox}
									/>
								}
								label={categories[id].title}
							/>
						</Grid>
					))}
				</Grid>
			</>
		)
	);
};

const LobbyPage = ({ gameId }) => {
	const classes = useStyles();
	const playersData = useSelector(getPlayersData(gameId));
	const gameConfig = useSelector(getConfig(gameId));
	const host = useSelector(getGameHost(gameId));
	const [claimActivityHostRequest, initiateClaimActivityHostRequest] =
		useBackendFunction('platform-claimAsActivityHost');

	const globalCategories = {};
	const customCategories = {};

	// bifurcate categories into global and custom ones
	if (gameConfig?.categories) {
		for (const [key, value] of Object.entries(gameConfig.categories)) {
			if (value.custom) customCategories[key] = value;
			else globalCategories[key] = value;
		}
	}

	const isHostConnected = !!playersData[host]?.connected;

	const triggerClaimHost = useCallback(() => {
		const index = gameId.indexOf('-');
		const params = {
			accountId: gameId.slice(0, index),
			gameId: gameId.slice(index + 1),
		};

		initiateClaimActivityHostRequest(params);
	}, [initiateClaimActivityHostRequest, gameId]);

	return (
		<>
			{!isHostConnected && (
				<Grid
					container
					mt={3}
					justifyContent="space-between"
					alignItems="center"
				>
					<Grid item>
						<Typography className={classes.players} variant="h5">
							Waiting for host to join...
						</Typography>
					</Grid>

					<Grid item>
						<Button
							style={{
								margin: 4,
							}}
							loading={isPending(claimActivityHostRequest)}
							onClick={triggerClaimHost}
							color="primary"
							className={classes.actionBtn}
						>
							Claim host
						</Button>
					</Grid>
				</Grid>
			)}
			<GamePlayers className={classes.players} players={playersData} />
			<div className={classes.gameSettings}>
				<Typography variant="h5" gutterBottom>
					Categories
				</Typography>

				<Box mb={2}>
					<CategoriesDisplay
						categories={customCategories}
						label="Custom Categories"
					/>
				</Box>

				<CategoriesDisplay
					categories={globalCategories}
					label="Global Categories"
				/>
			</div>
		</>
	);
};

const PreRoundPage = ({ gameId, categoryId, categoryName, onNext }) => {
	const classes = useStyles();
	const questionIntermission = useSelector(getQuestionIntermission(gameId));
	const { isMuted } = useContext(AudioControl);

	const audioFile = soundByCategory.get(categoryId) || sndGeneral;

	const [play, { stop }] = useSound(audioFile, {
		volume: isMuted ? 0 : 0.7,
		playbackRate: 1,
	});

	useEffect(() => {
		if (categoryId) {
			play();
			return stop;
		}
	}, [categoryId, play, stop]);

	return (
		<div className={classes.content}>
			<CategoryLogo category={categoryId} categoryName={categoryName} />
			<div className={classes.progressIndicator}>
				<CountdownIndicator
					duration={questionIntermission}
					onComplete={onNext}
					variant="large"
				/>
			</div>
			<Typography variant="h2">Get ready</Typography>
		</div>
	);
};

const RoundSummaryPage = ({ gameId, categoryId, categoryName, onNext }) => {
	const gameOver = useSelector(isGameOver(gameId));
	const classes = useStyles();
	return (
		<>
			<CategoryLogo
				className={classes.categoryLogo}
				category={categoryId}
				categoryName={categoryName}
				delay={0}
				size="small"
			/>
			<Leaderboard gameId={gameId} />
			<div className={classes.content}>
				{onNext && (
					<Button
						variant="contained"
						color="primary"
						size="large"
						className={classes.button}
						onClick={onNext}
						fullWidth
					>
						{gameOver ? 'Start a new game' : 'Start next round'}
					</Button>
				)}
				{gameOver && (
					<Button
						variant="contained"
						color="primary"
						size="large"
						className={classes.button}
						href={envHost()}
						fullWidth
					>
						Go to the Experience Hub
					</Button>
				)}
			</div>
		</>
	);
};

const QuestionPage = ({
	gameId,
	questionIndex,
	onTimesUp,
	onAnswer,
	onNext,
}) => {
	const classes = useStyles();
	const question = useSelector(getQuestion(gameId, questionIndex));
	const questionTextTransition = useTransition(question?.question, null, {
		from: { opacity: 0, transform: 'translateX(100%)' },
		enter: { opacity: 1, transform: 'translateX(0%)' },
		leave: {
			opacity: 0,
			transform: 'transformX(-100%)',
			position: 'absolute',
		},
	});
	const hasAnswer = question?.answer !== -1;
	const questionDuration = useSelector(getQuestionDuration(gameId));
	const questionIntermission = useSelector(getQuestionIntermission(gameId));
	const { isMuted } = useContext(AudioControl);

	const [play, { stop }] = useSound(sndThinkingBeeps, {
		volume: isMuted ? 0 : 1,
		playbackRate: 19000 / questionDuration,
	});

	useEffect(() => {
		if (!hasAnswer) {
			play();
			return stop;
		}
	}, [hasAnswer, play, stop]);

	if (!question) {
		return null;
	}
	return (
		<div key={question.id}>
			<div className={classes.content}>
				<div className={classes.questionTextContainer}>
					{questionTextTransition.map(({ item, key, props }) => (
						<animated.div key={key} style={props}>
							<Typography variant="h2">{item}</Typography>
						</animated.div>
					))}
				</div>
				<div className={classes.progressIndicator}>
					{!hasAnswer ? (
						<CountdownIndicator
							key="questionDuration"
							duration={questionDuration}
							onComplete={onTimesUp}
						/>
					) : (
						<CountdownIndicator
							key="questionIntermission"
							duration={questionIntermission}
							onComplete={onNext}
						/>
					)}
				</div>
			</div>
			<Options key={question.id} onAnswer={onAnswer} {...question} />
		</div>
	);
};

const getDocumentOffsetTop = (el, seed = 0) =>
	el ? getDocumentOffsetTop(el.offsetParent, el.offsetTop + seed) : seed;

// currently scrolls to center the paper vertically.
// This could be better. The draw back is that if you have a short screen after a long one, the footer starts to show.
// This kinda ruins the feel of the game.
//
// TODO: An alternative to try:
// For short screens, it should just scroll to very top.
// For longer ones that still fit on the screen, it should scroll to the bottom of the main content area
// For even longer ones it should scroll to the top of the paper.
const scrollIfOffscreen = (el, expectedHeight = el.offsetHeight) => {
	const docOffset = getDocumentOffsetTop(el);
	if (
		window.scrollY < docOffset &&
		window.scrollY + window.innerHeight > docOffset + expectedHeight
	) {
		return;
	}
	const scrollTop = docOffset - (window.innerHeight - expectedHeight) / 2;
	window.scrollTo({
		left: 0,
		top: scrollTop,
		behavior: 'smooth',
	});
};

const useMeasure = () => {
	const ref = useRef();
	const [props, setProps] = useState({ height: 0 });
	useEffect(() => {
		if (!ref.current) return;
		const el = ref.current;
		const observer = new ResizeObserver((entries) => {
			for (const e of entries) {
				if (e.target === el) {
					setProps({
						height: e.contentBoxSize
							? e.contentBoxSize[0]
								? e.contentBoxSize[0].blockSize
								: e.contentBoxSize.blockSize
							: e.contentRect.height,
					});
					return;
				}
			}
		});
		observer.observe(el);
		return () => observer.disconnect();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref.current]);
	return [ref, props];
};
