import React, { useEffect, useState } from 'react';
import {
	DocumentHead,
	ErrorMessage,
	useCreateGame,
	useCurrentUser,
} from '@remote-social/common';
import { useHistory, useLocation } from 'react-router-dom';
import {
	Box,
	Button,
	List,
	ListItem,
	ListItemText,
	Snackbar,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Alert } from '@material-ui/lab';
import Layout from '../layout/Layout';
import SchedulerFlow from '@remote-social/common/src/components/scheduling';
import { GameType } from '@contracts/platform';

const useStyles = makeStyles((theme) => ({
	remoteSocialLogo: {
		fill: theme.palette.secondary.main,
		width: 32,
		marginLeft: theme.spacing(1),
		display: 'inline-block',
		verticalAlign: 'text-top',
	},
	list: {
		maxWidth: 400,
		margin: '0 auto',
		'& li': {
			marginLeft: theme.spacing(6),
		},
	},
	listNumber: {
		fontSize: '2rem',
		position: 'absolute',
		right: 'calc(100%)',
		top: '0.5rem',
	},
	container: {
		overflow: 'visible',
	},
}));

export default function Home() {
	const classes = useStyles();
	const location = useLocation();
	const history = useHistory();
	const { isAuthenticated } = useCurrentUser();
	const { error, pending, createGame } = useCreateGame({
		gameType: GameType.trivia,
	});
	const [schedulerOpen, setSchedulerOpen] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	useEffect(() => {
		if (location.hash) {
			const [path, accountId] = location.hash.substring(1).split('/');

			if (path === 'create') {
				createGame(accountId);
			}
		}
	}, [createGame, location.hash]);

	const handleOpenScheduler = () => {
		setSchedulerOpen(true);
	};

	const handleCloseScheduler = () => {
		setSchedulerOpen(false);
	};

	return (
		<Layout maxWidth="sm">
			<DocumentHead title="Home" />
			<Typography align="center" gutterBottom variant="h4">
				Bring your team together
			</Typography>
			<List className={classes.list}>
				<ListItem>
					<ListItemText align="left">
						<Typography
							variant="h3"
							component="span"
							className={classes.listNumber}
						>
							<span role="img" aria-label="robot emoji">
								🤖
							</span>
						</Typography>
						Create a new game
					</ListItemText>
				</ListItem>
				<ListItem>
					<ListItemText align="left">
						<Typography
							variant="h3"
							component="span"
							className={classes.listNumber}
						>
							<span role="img" aria-label="woman laptop">
								👩‍💻
							</span>
						</Typography>
						Jump on a video call with everyone
					</ListItemText>
				</ListItem>
				<ListItem>
					<ListItemText align="left">
						<Typography
							variant="h3"
							component="span"
							className={classes.listNumber}
						>
							<span role="img" aria-label="link">
								🔗
							</span>
						</Typography>
						Share the link for friends to join
					</ListItemText>
				</ListItem>
			</List>
			{error && <ErrorMessage error={error} />}

			<Box mt={2} mb={2}>
				<Button
					fullWidth
					color="primary"
					variant="contained"
					size="large"
					onClick={() => history.push('#create')}
					loading={pending}
				>
					Play now
				</Button>
			</Box>

			{isAuthenticated && (
				<Box mt={2} mb={2}>
					<Button
						fullWidth
						color="primary"
						variant="contained"
						size="large"
						onClick={handleOpenScheduler}
						loading={pending}
					>
						Schedule a future game
					</Button>
				</Box>
			)}

			{isAuthenticated && (
				<>
					{schedulerOpen && (
						<SchedulerFlow
							game={{
								id: GameType.trivia,
								name: 'Trivia',
							}}
							isOpen={schedulerOpen}
							onRequestClose={handleCloseScheduler}
							onScheduleSuccess={() => setShowAlert(true)}
						/>
					)}
					<Snackbar
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						open={showAlert}
						onClose={() => setShowAlert(false)}
						autoHideDuration={5000}
					>
						<Alert
							variant="filled"
							severity="success"
							onClose={() => setShowAlert(false)}
						>
							Event is scheduled
						</Alert>
					</Snackbar>
				</>
			)}
		</Layout>
	);
}
