import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { Grid, lighten } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import Reward from 'react-rewards';
import { animated, useSpring, useTrail } from 'react-spring';
import useSound from 'use-sound';
import { AudioControl } from '@remote-social/common';

import sndWin from '../assets/audio/fireworks.mp3';
import sndLoose from '../assets/audio/awww.mp3';

const useStyles = makeStyles((theme) => ({
	options: {
		padding: theme.spacing(4, 2),
	},
	option: {
		width: '100%',
		margin: 0,
		height: 125,
		position: 'relative',
		fontSize: 14,
		fontFamily: theme.fontFamily,
		color: theme.palette.background.paper,
		backgroundColor: theme.palette.common.white,
		outline: 0,
		border: 0,
		fontWeight: 'bold',
		cursor: 'pointer',
		borderRadius: '1.25rem',
		transition: 'all 200ms ease-out',
		wordWrap: 'break-word',
		[theme.breakpoints.up('md')]: {
			fontSize: 20,
		},
	},
	selected: {
		opacity: 1 + '!important',
		zIndex: 1,
	},
	unselected: {
		opacity: 0.65,
	},
	optionLabel: {
		position: 'absolute',
		top: -12,
		width: 36,
		height: 36,
		left: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginLeft: -18,
		lineHeight: 1,
		fontWeight: 'bold',
		color: theme.palette.background.paper,
		backgroundColor: lighten(theme.palette.background.paper, 0.9),
		borderRadius: '100%',
		fontSize: 14,
		[theme.breakpoints.up('md')]: {
			fontSize: 24,
		},
	},
	optionA: {
		transformOrigin: '100% 100%',
	},
	optionB: {
		transformOrigin: '0% 100%',
	},
	optionC: {
		transformOrigin: '100% 0%',
	},
	optionD: {
		transformOrigin: '0% 0%',
	},
	correct: {
		opacity: 1,
	},
	incorrect: {
		opacity: 0.6,
	},
	icon: {
		position: 'absolute',
		top: 4,
		width: 36,
		height: 36,
		right: 4,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		lineHeight: 1,
		fontWeight: 'bold',
		color: theme.palette.getContrastText(theme.palette.background.paper),
		backgroundColor: theme.palette.background.paper,
		borderRadius: '100%',
	},
}));

export default function Options({ answer, options, onAnswer }) {
	const [selectedAnswer, setSelectedAnswer] = useState(-1);
	const classes = useStyles();
	const rewardRef = useRef([null, null, null, null]);
	const hasAnswer = answer !== -1;
	const trail = useTrail(options.length, {
		from: { x: 0 },
		x: 1,
		delay: 1000,
	});
	const { isMuted } = useContext(AudioControl);

	const [playWin, { stop: stopWin }] = useSound(sndWin, {
		volume: isMuted ? 0 : 1,
		playbackRate: 5500 / 5000,
	});

	const [playLose, { stop: stopLose }] = useSound(sndLoose, {
		volume: isMuted ? 0 : 1,
		playbackRate: 0.9,
	});

	useEffect(() => {
		if (hasAnswer) {
			if (selectedAnswer === answer) {
				// selected answer is correct
				playWin();
				return stopWin;
			} else {
				// selected answer is incorrect or no answer selected
				playLose();
				return stopLose;
			}
		}
	}, [
		selectedAnswer,
		hasAnswer,
		answer,
		playWin,
		playLose,
		stopWin,
		stopLose,
	]);

	let translate = 'translate(50%, 50%)';
	let incorrectAnswerTranslate = 'translate(50%, 300%)';

	if (selectedAnswer === 1) {
		translate = 'translate(-50%, 50%)';
		incorrectAnswerTranslate = 'translate(-50%, 300%)';
	} else if (selectedAnswer === 2) {
		translate = 'translate(50%, -50%)';
		incorrectAnswerTranslate = 'translate(50%, 200%)';
	} else if (selectedAnswer === 3) {
		translate = 'translate(-50%, -50%)';
		incorrectAnswerTranslate = 'translate(-50%, 200%)';
	}

	const handleAnswer = useCallback(
		(answer) => {
			setSelectedAnswer(answer);
			if (typeof onAnswer === 'function') {
				onAnswer(answer);
			}
		},
		[onAnswer],
	);

	const animationState = useRef(0);
	const selectedSpring = useSpring({
		from:
			animationState.current === 0
				? { transform: 'translate(0%, 0%)' }
				: animationState.current === 1
				? { transform: translate }
				: { transform: incorrectAnswerTranslate },
		to: async (next, cancel) => {
			if (!hasAnswer) {
				cancel();
				return;
			}

			if (animationState.current === 0) {
				await next({ transform: translate });
				animationState.current = 1;
			}

			const ref = rewardRef.current?.[selectedAnswer];
			if (selectedAnswer === answer) {
				if (typeof ref?.rewardMe === 'function') {
					ref.rewardMe();
				}
			} else {
				if (typeof ref?.punishMe === 'function') {
					ref.punishMe();
				}

				if (animationState.current === 1) {
					await next({ transform: incorrectAnswerTranslate });
					animationState.current = 2;
				}
			}
		},
	});

	const unselectedSpring = useSpring({
		transform: hasAnswer ? 'scale(0.75)' : 'scale(1)',
	});

	const handleClick = (index) => {
		if (hasAnswer) {
			return;
		}

		handleAnswer(index);
	};

	return (
		<Grid className={classes.options} container spacing={2}>
			{trail.map(({ x, ...rest }, index) => {
				const option = options[index];
				const letter = ['A', 'B', 'C', 'D'][index];
				const isAnswer = index === answer;
				const isSelected = index === selectedAnswer;
				const hasSelected = selectedAnswer !== -1;
				const isUnselected = hasSelected && !isSelected;
				const isCorrect = hasAnswer && isAnswer;
				const isIncorrect = hasAnswer && !isAnswer;

				return (
					<Grid key={option} item xs={6}>
						<animated.div
							style={{
								transform:
									!hasAnswer &&
									x.interpolate((x) => `scale(${x})`),
							}}
						>
							<animated.button
								style={
									isSelected
										? selectedSpring
										: unselectedSpring
								}
								className={clsx(
									classes.option,
									classes[`option${letter}`],
									isSelected && classes.selected,
									isUnselected && classes.unselected,
									isCorrect && classes.correct,
									isIncorrect && classes.incorrect,
								)}
								onClick={() => handleClick(index)}
								value={option}
								disabled={hasAnswer}
							>
								<div className={classes.optionLabel}>
									{letter}
								</div>
								{isCorrect && (
									<div className={classes.icon}>
										<CheckIcon />
									</div>
								)}
								{isIncorrect && isSelected && (
									<div className={classes.icon}>
										<ClearIcon />
									</div>
								)}
								<Reward
									ref={(el) => {
										rewardRef.current[index] = el;
									}}
									type={
										Math.round(Math.random) === 0
											? 'memphis'
											: 'confetti'
									}
									config={{ spread: 180 }}
								>
									{option}
								</Reward>
							</animated.button>
						</animated.div>
					</Grid>
				);
			})}
		</Grid>
	);
}
