import { firstValueFrom, Observable } from 'rxjs';
import { isDevBuild } from '../environment';

export function loadImage(src: string, crossOrigin?: 'anonymous') {
	return firstValueFrom(loadImageCancellable(src, crossOrigin));
}

// NOTE: We are using Observable because it also provides cancellation semantics
// unlike promises

export function loadImageCancellable(src: string, crossOrigin?: 'anonymous') {
	const error = new Error('Failed to load an image');
	return new Observable<HTMLImageElement>((subscriber) => {
		const img = new Image();
		img.src = src;
		if (crossOrigin && !isDevBuild()) {
			img.crossOrigin = crossOrigin;
		}

		const cleanup = () => {
			img.removeEventListener('load', onLoad);
			img.removeEventListener('error', onError);
		};

		const onError = () => {
			cleanup();
			subscriber.error(error);
		};

		const onLoad = () => {
			cleanup();
			subscriber.next(img);
			subscriber.complete();
		};

		if (img.complete) {
			subscriber.next(img);
			subscriber.complete();
			return;
		}

		img.addEventListener('load', onLoad);
		img.addEventListener('error', onError);

		return () => {
			// cancel request
			img.src = '';
			cleanup();
		};
	});
}
