import { createTheme } from '@material-ui/core';
import { createRemoteSocialTheme } from '@common/styles';
import { makeHSLA } from '@common/utils/colors';

export const triviaPurple = { h: 254, s: 84, l: 30, a: 1 };
export const triviaPurpleDark = { h: 254, s: 84, l: 20, a: 0 };

export const createTriviaTheme = () => {
	return createTheme(
		createRemoteSocialTheme({
			name: 'trivia',
			createPalette: () => ({
				type: 'dark',
				primary: {
					main: '#fff',
				},
				background: {
					paper: makeHSLA(triviaPurple),
				},
			}),
		}),
	);
};
