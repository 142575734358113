const getGame = (gameId) => (state) => state.firebase.data?.games?.[gameId];

export const getRoundId = (gameId) => (state) => {
	const ids = Object.keys(
		state.firebase.data?.games?.[gameId]?.roundData || {},
	);

	return ids?.[ids.length - 1];
};

export const getPreviousRoundId = (gameId) => (state) => {
	const ids = Object.keys(
		state.firebase.data?.games?.[gameId]?.roundData || {},
	);

	return ids?.[ids.length - 2];
};

export const getTriviaCategories = (state) => {
	const categories = state.firestore.data?.['games/trivia/categories'] || {};
	const filteredCategories = {};
	if (categories) {
		const categoriesArray = Object.keys(categories);
		categoriesArray.forEach((category) => {
			const isDisabled = categories[category]?.published === false;
			if (!isDisabled) {
				filteredCategories[category] = categories[category];
			}
		});
	}
	return filteredCategories;

	// return state.firestore.data?.['games/trivia/categories'] || {};
};

export const getPreviousCategoryId = (gameId) => (state) => {
	const roundId = getPreviousRoundId(gameId)(state);

	return state.firebase.data?.games?.[gameId]?.roundData?.[roundId]?.category;
};

export const getCurrentCategoryId = (gameId) => (state) => {
	const roundId = getRoundId(gameId)(state);

	return state.firebase.data?.games?.[gameId]?.roundData?.[roundId]?.category;
};

export const getCurrentCategoryName = (gameId) => (state) => {
	const roundId = getRoundId(gameId)(state);

	return state.firebase.data?.games?.[gameId]?.roundData?.[roundId]
		?.categoryName;
};

export const getCurrentCategory = (gameId) => (state) => {
	const roundId = getRoundId(gameId)(state);
	const categories = getTriviaCategories(state);
	const categoryId =
		state.firebase.data?.games?.[gameId]?.roundData?.[roundId]?.category;

	return categories?.[categoryId]?.name;
};

export const getQuestionIndexToAnswer = (gameId) => (state) => {
	const roundId = getRoundId(gameId)(state);
	const questions =
		state.firebase.data?.games?.[gameId]?.roundData?.[roundId]?.questions ||
		[];
	return questions.findIndex((q) => q.answer === -1);
};

export const getQuestion = (gameId, index) => (state) => {
	const roundId = getRoundId(gameId)(state);
	const questions =
		state.firebase.data?.games?.[gameId]?.roundData?.[roundId]?.questions ||
		[];

	if (index === -1) {
		return null;
	}

	return questions[index];
};

export const getQuestionCount = (gameId) => (state) => {
	const roundId = getRoundId(gameId)(state);
	const questions =
		state.firebase.data?.games?.[gameId]?.roundData?.[roundId]?.questions ||
		[];

	return questions.filter(({ answer }) => answer !== -1).length;
};

export const getRoundNumber = (gameId) => (state) => {
	return Object.keys(state.firebase.data?.games?.[gameId]?.roundData || {})
		.length;
};

export const getTotalQuestions = (gameId) => (state) => {
	const roundId = getRoundId(gameId)(state);

	return (
		state.firebase.data?.games?.[gameId]?.roundData?.[roundId]
			?.totalQuestions || 0
	);
};

export const isGameOver = (gameId) => (state) => {
	const game = getGame(gameId)(state);

	const questionCount = getQuestionCount(gameId)(state);
	const totalQuestions = getTotalQuestions(gameId)(state);

	const roundsPlayed = Object.keys(game?.roundData || {}).length;
	const roundsExpected = (game?.config?.categories || []).length;
	return (
		roundsPlayed === roundsExpected &&
		questionCount === totalQuestions &&
		roundsExpected > 0 &&
		totalQuestions > 0
	);
};

/**
 * Accumulates all player data and scores from each round into a single array, sorted by score
 * @param gameId
 * @returns {function(*): *}
 */
export const getPlayerScores = (gameId) => (state) => {
	const gameData = state.firebase.data?.games?.[gameId];
	const playersData = gameData?.playersData || {};
	const roundData = gameData?.roundData || {};

	const playerScores = Object.keys(playersData).reduce(
		(result, playerId) => ({
			...result,
			[playerId]: {
				...playersData[playerId],
				id: playerId,
				score: 0,
			},
		}),
		{},
	);

	const rounds = Object.keys(roundData);

	rounds.forEach((roundId) => {
		const round = roundData[roundId];

		if (!round.questions) {
			return;
		}

		round.questions.forEach((question) => {
			if (!question.answers) {
				return;
			}

			Object.values(question.answers).forEach((scores) => {
				Object.keys(scores).forEach((playerId) => {
					playerScores[playerId].score += scores[playerId];
				});
			});
		});
	});

	return Object.values(playerScores).sort((a, b) => b.score - a.score);
};

export const getPlayerAnswers = (gameId, questionId) => (state) => {
	const gameData = state.firebase.data?.games?.[gameId];
	const playersData = gameData?.playersData || {};
	const roundData = gameData?.roundData || {};
	const roundId = getRoundId(gameId)(state);
	const round = roundData[roundId];
	const question = (round?.questions || []).find(
		({ id }) => id === questionId,
	);

	return ['A', 'B', 'C', 'D'].reduce((result, letter, index) => {
		if (!question?.answers?.[index]) {
			return {
				...result,
				[letter]: [],
			};
		}

		return {
			...result,
			[letter]: Object.keys(question.answers[index]).map(
				(playerId) => playersData[playerId],
			),
		};
	}, {});
};

export const getQuestionDuration = (gameId) => (state) => {
	return (
		state.firebase.data?.games?.[gameId]?.config?.questionDuration || 20000
	);
};

export const getQuestionIntermission = (gameId) => (state) => {
	return (
		state.firebase.data?.games?.[gameId]?.config?.questionIntermission ||
		10000
	);
};
