import { combineReducers } from '@reduxjs/toolkit';
import { firebaseReducer, storageReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

// import authReducer from '../features/auth/authSlice';

const rootReducer = combineReducers({
	firebase: firebaseReducer,
	firestore: firestoreReducer,
	storage: storageReducer,
	// auth: authReducer
});

export default rootReducer;
