import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { GameProviders, initCommon } from '@remote-social/common';
import App from './App';
import { AppProvider } from './store/context';

initCommon();

const render = (Component) => {
	return ReactDOM.render(
		<AppProvider>
			<GameProviders>
				<Component />
			</GameProviders>
		</AppProvider>,
		document.getElementById('root'),
	);
};

render(App);

// add module hotswapping to reload modules in development mode
if (process.env.NODE_ENV !== 'production') {
	if (module.hot) {
		module.hot.accept('./App', () => {
			const NextApp = require('./App').default;
			render(NextApp);
		});
	}
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
