import React from 'react';
import { 
	FACEBOOK,
	GOOGLE, 
} from '../../hooks';
import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { SocialLoginButton } from '../../components/socialLoginButton';
import { AuthErrorMessage } from '../../components/authErrorMessage';
import { isLogoutContinueParameter } from '../../routes/isLogoutContinueParameter';
import { useHistory } from 'react-router-dom';
import { UserMarketing } from '@contracts/platform';
import { ErrorType } from '../../errors';
import { LocationDescriptor } from 'history';

const useStyles = makeStyles((theme: Theme) => ({
	orText: {
		fontWeight: 'bold',
	},
	socialButton: {
		margin: theme.spacing(1, 0, 2),
		width: '100%',
		color: '#FFFFFF',
		background: '#444444',
	},
}));

type Props = {
	mode?: 'login' | 'link';
	nextUrl?: LocationDescriptor;
	userAcquisitionData?: UserMarketing;
};

export const SocialLogin = ({
	mode = 'login',
	nextUrl,
	userAcquisitionData,
}: Props) => {
	const history = useHistory();
	const styles = useStyles();

	const [error, setError] = React.useState<ErrorType | null>(null);

	const onComplete = React.useCallback(() => {
		nextUrl && !isLogoutContinueParameter(nextUrl) && history.push(nextUrl);
	}, [history, nextUrl]);

	return (
		<Grid container direction="row" spacing={1}>
			<Grid item xs={12}>
				<Grid container direction="row" spacing={1}>
					<Grid item xs={12}>
						<SocialLoginButton
							mode={mode}
							setError={setError}
							providerID={GOOGLE}
							className={styles.socialButton}
							userAcquisitionData={userAcquisitionData}
							onComplete={onComplete}
						/>
					</Grid>
					{/* <Grid item xs={6}>
						<SocialLoginButton
							mode={mode}
							setError={setError}
							providerID={FACEBOOK}
							className={styles.socialButton}
							userAcquisitionData={userAcquisitionData}
							onComplete={onComplete}
						/>
					</Grid> */}
					{error && (
						<Grid item xs={12}>
							<AuthErrorMessage error={error} />
						</Grid>
					)}
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body1" align="center">
					<strong>or</strong>
				</Typography>
			</Grid>
		</Grid>
	);
};
