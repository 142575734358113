import React, { useEffect, useState } from 'react';
import {
	CopyText,
	DocumentHead,
	GamePlayers,
	getConfig,
	getGameHost,
	getGameState,
	getPlayersData,
	isPending,
	registerError,
	useCurrentAccount,
	useCurrentUser,
	useGame,
} from '@remote-social/common';
import { Redirect, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Grid,
	Link,
	Tooltip,
	Typography,
} from '@material-ui/core';
import {
	useFirebase,
	useFirebaseConnect,
	useFirestoreConnect,
} from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import Layout from '../layout/Layout';
import { envHost } from '@remote-social/common/src/utils/envLink';
import { GameState } from '@contracts/platform';

import RefreshIcon from '@material-ui/icons/Refresh';
import LaunchIcon from '@material-ui/icons/Launch';
import { useBackendFunction } from '@remote-social/common/src/hooks/useBackendFunction';

const MINIMUM_CATEGORIES = 2;

const useStyles = makeStyles((theme) => ({
	players: {
		marginBottom: theme.spacing(4),
	},
	copyText: {
		marginBottom: theme.spacing(4),
	},
	divider: {
		margin: theme.spacing(4, 0),
	},
	checkbox: {
		width: '100%',
	},
	popupWindow: {
		maxWidth: 340,
		border: '3px solid white',
		borderRadius: 6,
		overflow: 'hidden',
	},
	noMargin: {
		margin: 0,
	},
}));

export default function Create() {
	const { account: accountId, game } = useParams();
	const { currentAccountId, setCurrentAccount } = useCurrentAccount();
	const { owned } = useCurrentAccount();
	const classes = useStyles();
	const [isStartingGame, setIsStartingGame] = useState(false);
	const user = useCurrentUser();
	const firebase = useFirebase();
	const gameId = accountId && game ? `${accountId}-${game}` : null;
	const host = useSelector(getGameHost(gameId));
	const playersData = useSelector(getPlayersData(gameId));
	const gameConfig = useSelector(getConfig(gameId));
	const gameState = useSelector(getGameState(gameId));
	const isGameInitialised = Boolean(host);
	const hostIsAccountOwner = owned.some(
		(account) => account.accountId === accountId,
	);

	const [updateGameConfigRequest, updateGameConfig] = useBackendFunction(
		'trivia-updateConfig',
	);

	const linkToCustomizer = `${envHost()}/settings/activity?activity=Trivia`;

	// store local category selection state to prevent `Controlled input mutation` error
	const [selectedCategories, setSelectedCategories] = useState([]);

	const hasGameStarted = gameState === GameState.game;
	const isHost = host === user.uid;

	// update local selected categories state when remote config changes
	useEffect(() => {
		if (gameConfig?.categories) {
			const categories = Object.keys(gameConfig.categories)
				.map((id) => (gameConfig.categories[id].selected ? id : false))
				.filter(Boolean);

			setSelectedCategories(categories);
		}
	}, [gameConfig]);

	// check if the game can start
	// Conditions:
	// 1. number of connected players > 0
	// 2. number of trivia categories selected > MINIMUM_CATEGORIES
	const canStartGame =
		Boolean(
			Object.values(playersData).filter(({ connected }) => connected)
				.length > 0,
		) && selectedCategories.length >= MINIMUM_CATEGORIES;

	const playerPath = gameId && `/game/${accountId}/${game}`;

	useGame(gameId);

	// setup db subscriptions
	useFirebaseConnect(
		gameId && [
			{
				path: `/games/${gameId}/playersData`,
			},
			{
				path: `/games/${gameId}/host`,
			},
			{
				path: `/games/${gameId}/state`,
			},
			{
				path: `/games/${gameId}/config`,
			},
		],
	);

	useFirestoreConnect([{ collection: 'games/trivia/categories' }]);

	useEffect(() => {
		if (accountId && accountId !== currentAccountId) {
			setCurrentAccount(accountId);
		}
	}, [accountId, currentAccountId, setCurrentAccount]);

	const handleCategoriesChange = async (e) => {
		if (isPending(updateGameConfigRequest)) {
			console.log('updating values, please wait');
			return;
		}

		const { value, checked } = e.target;

		const newCategories = [...selectedCategories, value].filter(
			(v) => !(v === value && !checked),
		);

		// update local state
		setSelectedCategories(newCategories);

		// { xmas: { selected: true } }
		let categories = {
			...gameConfig.categories,
		};

		Object.keys(categories).map((id) => {
			let selected = newCategories.includes(id);

			categories[id] = {
				...categories[id],
				selected,
			};

			return true;
		});

		const newGameConfig = {
			...gameConfig,
			categories,
		};

		updateGameConfig({
			gameId,
			config: newGameConfig,
		});
	};

	const handleStartGame = async () => {
		const startGame = firebase
			.functions()
			.httpsCallable('trivia-startGame');

		setIsStartingGame(true);

		try {
			await startGame({
				gameId,
				gameConfig,
			});
		} catch (e) {
			registerError(e);
			setIsStartingGame(false);
		}
	};

	if (!gameId) {
		return <Redirect to="/" />;
	}

	if (isGameInitialised && (!isHost || hasGameStarted)) {
		return <Redirect to={playerPath} />;
	}

	const globalCategories = {};
	const customCategories = {};

	// bifurcate categories into global and custom ones
	if (gameConfig?.categories) {
		for (const [key, value] of Object.entries(gameConfig.categories)) {
			if (value.custom) customCategories[key] = value;
			else globalCategories[key] = value;
		}
	}

	const refreshGameCategories = async () => {
		const refreshCategories = firebase
			.functions()
			.httpsCallable('trivia-refreshGameCategories');

		try {
			await refreshCategories({
				gameId,
			});
		} catch (e) {
			registerError(e);
		}
	};

	return (
		<Layout maxWidth="sm">
			<DocumentHead title="Create Game" />
			<GamePlayers className={classes.players} players={playersData} />
			<Typography variant="h5">Share this link to invite</Typography>
			<CopyText
				className={classes.copyText}
				text={playerPath && `${envHost('trivia')}${playerPath}`}
			/>
			{gameConfig?.categories &&
				Object.keys(gameConfig?.categories).length > 0 && (
					<>
						<Tooltip
							PopperProps={{
								disablePortal: true,
							}}
							placement="bottom"
							open={
								selectedCategories.length < MINIMUM_CATEGORIES
							}
							disableFocusListener
							disableHoverListener
							disableTouchListener
							title={`Please select at least ${MINIMUM_CATEGORIES} categories.`}
							arrow
						>
							<FormGroup>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="space-between"
								>
									<Typography variant="h5">
										Custom Categories
									</Typography>

									<Tooltip
										title="Cannot see a recent published custom Trivia category? Click here to refresh categories"
										aria-label="refresh categories"
										disableTouchListener
										arrow
									>
										<Button
											onClick={refreshGameCategories}
											variant="contained"
											color="primary"
											size="small"
											aria-label="refresh categories"
											startIcon={
												<RefreshIcon fontSize="small" />
											}
											className={classes.noMargin}
										>
											Refresh
										</Button>
									</Tooltip>
								</Box>
								{Object.keys(customCategories).length > 0 && (
									<>
										<Grid container>
											{Object.keys(customCategories).map(
												(id) => (
													<Grid key={id} item sm={6}>
														<FormControlLabel
															className={
																classes.checkbox
															}
															control={
																<Checkbox
																	onChange={
																		handleCategoriesChange
																	}
																	value={id}
																	checked={selectedCategories.includes(
																		id,
																	)}
																	color="primary"
																/>
															}
															label={
																gameConfig
																	.categories[
																	id
																].title
															}
														/>
													</Grid>
												),
											)}
										</Grid>
									</>
								)}
								<Box display="flex">
									{hostIsAccountOwner && (
										<Link
											rel="nofollow noreferrer"
											href={linkToCustomizer}
											target="_blank"
											className={classes.noMargin}
										>
											<Typography variant="body1">
												Create custom categories here{' '}
												<LaunchIcon fontSize="inherit" />
											</Typography>
										</Link>
									)}
								</Box>

								<Box height={20} />
								<Typography variant="h5">
									Global Categories
								</Typography>
								<Grid container>
									{Object.keys(globalCategories).map((id) => (
										<Grid key={id} item sm={6}>
											<FormControlLabel
												className={classes.checkbox}
												control={
													<Checkbox
														onChange={
															handleCategoriesChange
														}
														value={id}
														checked={selectedCategories.includes(
															id,
														)}
														color="primary"
													/>
												}
												label={
													gameConfig.categories[id]
														.title
												}
											/>
										</Grid>
									))}
								</Grid>
							</FormGroup>
						</Tooltip>
					</>
				)}
			<Box mt={2} mb={2}>
				<Button
					variant="contained"
					color="primary"
					size="large"
					onClick={handleStartGame}
					loading={isStartingGame}
					disabled={!canStartGame}
					fullWidth
				>
					Start Trivia, everyone's here
				</Button>
			</Box>
		</Layout>
	);
}
